/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  constructor() { }

  setHeader() {
    const options = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Accept: 'application/json'
    };

    return options;
  }

  setHeaderWithToken(token) {
    const options = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Accept: 'application/json',
      Authorization: 'Bearer ' + token
    };

    return options;
  }

  setHeaderWithTokenAndMultiPart(token) {
    const options = {
      'Access-Control-Allow-Origin': '*',
      Accept: 'application/json',
      Authorization: 'Bearer ' + token
    };

    return options;
  }

}
