/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginGuard } from './services/guard/login-guard.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/dashboard/tabs/tabs.module').then(m => m.TabsPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/signup/signup.module').then(m => m.SignupPageModule)
  },
  {
    path: 'verification',
    loadChildren: () => import('./pages/modals/verification/verification.module').then(m => m.VerificationPageModule)
  },
  {
    path: 'use-terms',
    loadChildren: () => import('./pages/dashboard/modals/use-terms/use-terms.module').then(m => m.UseTermsPageModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./pages/dashboard/modals/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyPageModule)
  },
  {
    path: 'categories',
    loadChildren: () => import('./pages/modals/categories/categories.module').then(m => m.CategoriesPageModule)
  },
  {
    path: 'states',
    loadChildren: () => import('./pages/modals/states/states.module').then(m => m.StatesPageModule)
  },
  {
    path: 'cities',
    loadChildren: () => import('./pages/modals/cities/cities.module').then(m => m.CitiesPageModule)
  },
  {
    path: 'services',
    loadChildren: () => import('./pages/modals/services/services.module').then(m => m.ServicesPageModule)
  },
  {
    path: 'tutorial',
    loadChildren: () => import('./pages/modals/tutorial/tutorial.module').then(m => m.TutorialPageModule)
  },
  {
    path: 'add-locality',
    loadChildren: () => import('./pages/dashboard/modals/add-locality/add-locality.module').then(m => m.AddLocalityPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'add-schedule',
    loadChildren: () => import('./pages/dashboard/modals/add-schedule/add-schedule.module').then(m => m.AddSchedulePageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'edit-profile',
    loadChildren: () => import('./pages/dashboard/profile/edit-profile/edit-profile.module').then(m => m.EditProfilePageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'list-locality',
    loadChildren: () => import('./pages/dashboard/profile/list-locality/list-locality.module').then(m => m.ListLocalityPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'list-schedule',
    loadChildren: () => import('./pages/dashboard/profile/list-schedule/list-schedule.module').then(m => m.ListSchedulePageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'list-service',
    loadChildren: () => import('./pages/dashboard/profile/list-service/list-service.module').then(m => m.ListServicePageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'list-card',
    loadChildren: () => import('./pages/dashboard/profile/list-card/list-card.module').then(m => m.ListCardPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'list-insurance',
    loadChildren: () => import('./pages/dashboard/profile/list-insurance/list-insurance.module').then(m => m.ListInsurancePageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'appointment-details',
    loadChildren: () => import('./pages/dashboard/modals/appointment-details/appointment-details.module').then(m => m.AppointmentDetailsPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'change-password',
    loadChildren: () => import('./pages/dashboard/modals/change-password/change-password.module').then(m => m.ChangePasswordPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'change-email',
    loadChildren: () => import('./pages/dashboard/modals/change-email/change-email.module').then(m => m.ChangeEmailPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'change-phone',
    loadChildren: () => import('./pages/dashboard/modals/change-phone/change-phone.module').then(m => m.ChangePhonePageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'send-documents',
    loadChildren: () => import('./pages/dashboard/modals/send-documents/send-documents.module').then(m => m.SendDocumentsPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'see-my-profile',
    loadChildren: () => import('./pages/dashboard/modals/see-my-profile/see-my-profile.module').then(m => m.SeeMyProfilePageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'suggest-service',
    loadChildren: () => import('./pages/dashboard/modals/suggest-service/suggest-service.module').then(m => m.SuggestServicePageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'suggest-cards',
    loadChildren: () => import('./pages/dashboard/modals/suggest-cards/suggest-cards.module').then(m => m.SuggestCardsPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'suggest-insurances',
    loadChildren: () => import('./pages/dashboard/modals/suggest-insurances/suggest-insurances.module').then(m => m.SuggestInsurancesPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'change-about',
    loadChildren: () => import('./pages/dashboard/modals/change-about/change-about.module').then(m => m.ChangeAboutPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'support',
    loadChildren: () => import('./pages/dashboard/profile/support/support.module').then(m => m.SupportPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'see-faq',
    loadChildren: () => import('./pages/dashboard/modals/see-faq/see-faq.module').then(m => m.SeeFaqPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'send-suggestion',
    loadChildren: () => import('./pages/dashboard/modals/send-suggestion/send-suggestion.module').then(m => m.SendSuggestionPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'bug-report',
    loadChildren: () => import('./pages/dashboard/modals/bug-report/bug-report.module').then(m => m.BugReportPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'annuity',
    loadChildren: () => import('./pages/dashboard/modals/annuity/annuity.module').then(m => m.AnnuityPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'steps',
    loadChildren: () => import('./pages/dashboard/modals/steps/steps.module').then(m => m.StepsPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'invoice-history',
    loadChildren: () => import('./pages/dashboard/modals/invoice-history/invoice-history.module').then(m => m.InvoiceHistoryPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'edit-professional-profile',
    loadChildren: () => import('./pages/dashboard/profile/edit-professional-profile/edit-professional-profile.module').then(m => m.EditProfessionalProfilePageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'change-professional-emails',
    loadChildren: () => import('./pages/dashboard/modals/change-professional-emails/change-professional-emails.module').then(m => m.ChangeProfessionalEmailsPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'change-contact-means',
    loadChildren: () => import('./pages/dashboard/modals/change-contact-means/change-contact-means.module').then(m => m.ChangeContactMeansPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'set-attendance-radius',
    loadChildren: () => import('./pages/dashboard/modals/set-attendance-radius/set-attendance-radius.module').then(m => m.SetAttendanceRadiusPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'add-personal-locality',
    loadChildren: () => import('./pages/dashboard/modals/add-personal-locality/add-personal-locality.module').then(m => m.AddPersonalLocalityPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'urgency',
    loadChildren: () => import('./pages/dashboard/home/urgency/urgency.module').then(m => m.UrgencyPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'emergency-appointments',
    loadChildren: () => import('./pages/dashboard/home/emergency-appointments/emergency-appointments.module').then(m => m.EmergencyAppointmentsPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'create-service-history',
    loadChildren: () => import('./pages/dashboard/home/service-history/service-history-details/create-service-history/create-service-history.module').then(m => m.CreateServiceHistoryPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'service-history-details',
    loadChildren: () => import('./pages/dashboard/home/service-history/service-history-details/service-history-details.module').then(m => m.ServiceHistoryDetailsPageModule)
  },
  {
    path: 'list-service-history',
    loadChildren: () => import('./pages/dashboard/home/urgency/list-service-history/list-service-history.module').then(m => m.ListServiceHistoryPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'news-details',
    loadChildren: () => import('./pages/dashboard/news/news-details/news-details.module').then(m => m.NewsDetailsPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'urgency-access-details',
    loadChildren: () => import('./pages/dashboard/home/urgency/urgency-access-details/urgency-access-details.module').then(m => m.UrgencyAccessDetailsPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'news',
    loadChildren: () => import('./pages/dashboard/news/news.module').then(m => m.NewsPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'appointments',
    loadChildren: () => import('./pages/dashboard/home/appointments/appointments.module').then(m => m.AppointmentsPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'service-history',
    loadChildren: () => import('./pages/dashboard/home/service-history/service-history.module').then(m => m.ServiceHistoryPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'ratings',
    loadChildren: () => import('./pages/dashboard/home/ratings/ratings.module').then(m => m.RatingsPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'customers',
    loadChildren: () => import('./pages/dashboard/home/customers/customers.module').then(m => m.CustomersPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'plans',
    loadChildren: () => import('./pages/dashboard/modals/plans/plans.module').then(m => m.PlansPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'support-history',
    loadChildren: () => import('./pages/dashboard/modals/support-history/support-history.module').then(m => m.SupportHistoryPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'chats-customers',
    loadChildren: () => import('./pages/dashboard/chat-category/chats-customers/chats-customers.module').then(m => m.ChatsCustomersPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'customers-messages',
    loadChildren: () => import('./pages/dashboard/chat-category/chats-customers/customers-messages/customers-messages.module').then(m => m.CustomersMessagesPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'customers-wait-list',
    loadChildren: () => import('./pages/dashboard/chat-category/chats-customers/customers-wait-list/customers-wait-list.module').then(m => m.CustomersWaitListPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'chats-professionals',
    loadChildren: () => import('./pages/dashboard/chat-category/chats-professionals/chats-professionals.module').then(m => m.ChatsProfessionalsPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'professionals-messages',
    loadChildren: () => import('./pages/dashboard/chat-category/chats-professionals/professionals-messages/professionals-messages.module').then(m => m.ProfessionalsMessagesPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'professionals-wait-list',
    loadChildren: () => import('./pages/dashboard/chat-category/chats-professionals/professionals-wait-list/professionals-wait-list.module').then(m => m.ProfessionalsWaitListPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'list-days-off',
    loadChildren: () => import('./pages/dashboard/profile/list-days-off/list-days-off.module').then(m => m.ListDaysOffPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'add-day-off',
    loadChildren: () => import('./pages/dashboard/modals/add-day-off/add-day-off.module').then(m => m.AddDayOffPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'customer-profile',
    loadChildren: () => import('./pages/dashboard/modals/customer-profile/customer-profile.module').then(m => m.CustomerProfilePageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'professional-profile',
    loadChildren: () => import('./pages/dashboard/modals/professional-profile/professional-profile.module').then(m => m.ProfessionalProfilePageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'dimensioning',
    loadChildren: () => import('./pages/dashboard/home/dimensioning/dimensioning.module').then(m => m.DimensioningPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'blocked',
    loadChildren: () => import('./pages/dashboard/modals/blocked/blocked.module').then(m => m.BlockedPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'hiring-publishes',
    loadChildren: () => import('./pages/dashboard/profile/hiring-publishes/hiring-publishes.module').then(m => m.HiringPublishesPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'remaining-time',
    loadChildren: () => import('./pages/dashboard/modals/remaining-time/remaining-time.module').then(m => m.RemainingTimePageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'buy-online-attendance-time',
    loadChildren: () => import('./pages/dashboard/modals/buy-online-attendance-time/buy-online-attendance-time.module').then(m => m.BuyOnlineAttendanceTimePageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'change-plan',
    loadChildren: () => import('./pages/dashboard/modals/change-plan/change-plan.module').then(m => m.ChangePlanPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'system-payments',
    loadChildren: () => import('./pages/dashboard/profile/system-payments/system-payments.module').then(m => m.SystemPaymentsPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'my-credit-cards',
    loadChildren: () => import('./pages/dashboard/profile/my-credit-cards/my-credit-cards.module').then(m => m.MyCreditCardsPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'new-credit-card',
    loadChildren: () => import('./pages/dashboard/profile/my-credit-cards/new-credit-card/new-credit-card.module').then(m => m.NewCreditCardPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'service-contract',
    loadChildren: () => import('./pages/dashboard/modals/service-contract/service-contract.module').then(m => m.ServiceContractPageModule)
  },
  {
    path: 'prontuary',
    loadChildren: () => import('./pages/dashboard/modals/prontuary/prontuary.module').then( m => m.ProntuaryPageModule),
    canActivate: [LoginGuard]
  },  {
    path: 'saq',
    loadChildren: () => import('./pages/dashboard/modals/saq/saq.module').then( m => m.SaqPageModule)
  }




];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
